/***************** 
* General Styles *
*****************/
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
}

.section-styling{
  padding: 0 24px;
  margin-bottom: 108px;
}

a{
  text-decoration: none;
  color: inherit;
  width: fit-content;
  height: 100%;
  /* display: inline; */
}
