.hero {
  width: 100vw;
  /* padding-top: var(--navbar-height); */
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* align-items: center; */
  /* text-align: center; */
}
.hero_imageContainer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: -10;
}
.hero_svgImage {
  width: 200px;
  height: 200px;
  position: relative;
  left: 10%;
  top: 15%;
  opacity: 0.3;
}
/* .notebook_svgImage{
  width: 40px;
  height: 40px;
  position: relative;
  top: 30%;
  left: 30%;
  fill: red;
} */
