/******************* 
* Global Variables *
*******************/
@import url("https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@400;700&display=swap");

@font-face {
  font-family: "Futura Custom";
  src: url("./assets/fonts/futura\ light\ bt.ttf") format("truetype");
  font-weight: light;
  font-style: normal;
}
@font-face {
  font-family: "Futura Custom";
  src: url("./assets/fonts/Futura\ Book\ font.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Futura Custom";
  src: url("./assets/fonts/futura\ medium\ bt.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: "Calibre";
  src: url("./assets/fonts/CalibreRegular.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}

:root {
  /* --drk-backgrnd-clr: rgb(2, 11, 22);
    --light-backgrnd-clr: #fbfbfb;
    --zima-blue:#5bc2e7 */
  --dark-gradient: linear-gradient(
    90deg,
    rgba(7, 32, 65, 1) 0%,
    rgb(2, 11, 22) 100%
  );
  --dark-gradient-opaque: linear-gradient(
    90deg,
    rgba(7, 32, 65, 0.8) 0%,
    rgba(2, 11, 22, 0.8) 100%
  );
  --navbar-height: 56px;
}
